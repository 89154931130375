import React, { Component } from 'react'
import { Navbar,Modal } from "react-bootstrap";
import "../styles/index.css"
import jwt_decode from "jwt-decode";
import validator from 'validator'
import { useEffect, useState } from "react";
//import Table from "../views/FileTable1";
import FileTable from './FileTable';
//import DisplayTable from './DisplayTable';
import axios from 'axios';
//import {DropDownMenu,MenuItem,MuiThemeProvider} from 'material-ui';
//import { Button } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import { CloseButton } from 'react-toastify/dist/components';

export default class LandingPage extends Component {

  constructor(props) {
    super(props);

    var token = localStorage.getItem("accessToken");
    if (token) {
      var decoded = jwt_decode(token);
      this.state = {
        selectValue: '',
        fileDetails: [],
        decoded: decoded,
        beId: decoded.extension_beId.split(','),
        prefix: "fh_",
        progress: false,
        open: false
      }
    } else {
      window.location.reload();
    }
    this.handleChange = this.handleChange.bind(this);
    this.fileSize = this.fileSize.bind(this);
    //const classes=useStyles();
    //toast.configure();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {

    this.setState({ selectValue: event.target.value, fileDetails: [],open: true },
      () => {
        this.getListFiles(this.state.selectValue);

      }
    )

  }

  //This function is not used
  downloadStorageFile(row) {
    //console.log(this.state.selectValue)
    //console.log(row.FileName);
    const toast1 = toast.loading("Download is started",
    { type: toast.TYPE.INFO, 
      closeButton:true});

    //this.setState({progress:true})
    
      console.log('https://cost-transparency-dev.uk.r.appspot.com/storageexplorer/downloadfile?bucketid=' + this.state.selectValue + '&filename=' + row.FileName)
      axios({
        url: 'https://cost-transparency-dev.uk.r.appspot.com/storageexplorer/downloadfile?bucketid=' + this.state.selectValue + '&filename=' + row.FileName,
        method: 'GET',
        responseType: 'blob',
        /*onDownloadProgress: progressEvent => {
          let { progress } = this.state;
          progress = (progressEvent.loaded / progressEvent.total) * 100;
          this.setState({ progress });
        }*/
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        setTimeout(() => {
        link.setAttribute('download', row.FileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }, 3000)
      toast.update(toast1, {
        render: "Download is in progress - " +  'Filename   ' + row.FileName,
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
        closeButton: true,
        isLoading: false
     });
        this.setState({ progress: false })
      })
        .catch((error) => {
          console.log("error" + error)
          this.setState({ progress: false })
          if (error) {
            toast.update(toast1, {
              render: error.message + " - " + " Due to an error, please click the URL link to download the direct file.",
              type: toast.TYPE.ERROR,
              autoClose: 2000,
              closeButton: true,
              isLoading: false
           });
          }
        })


  }

  componentDidMount() {
    const value = this.state.prefix + this.state.beId[0];
    this.getListFiles(value);

  }

 // To get the list of files detail from DotNet API
  getListFiles(input) {
    axios.get(process.env.REACT_APP_GETFILELIST_API_URL+input)
      .then(response => {
        console.log(response.data);
        this.setState({
          fileDetails: response.data,
          open: false
        });
        //window.location.reload();
      })
      // .then((res) => {
      //   setTimeout(()=>{
      //     setIsLoading(false)
      //   }, 2000)
      // })

      .catch((error) => {
        console.log(error)
        //setIsLoading(false);
      })
      .finally(() => {
        this.setState({ open: false });
      })

  }

  //To get the total file size
  fileSize(){
    if (this.state.fileDetails.length >0){
      var totalSize = 0;
      for (var i = 0; i < this.state.fileDetails.length; i++) {
        totalSize = totalSize + this.state.fileDetails[i].Size;
        //console.log("fileSize", totalSize/1024);
      }
      totalSize= totalSize.toFixed(2)
    }else{
      var totalSize = 0;
    }
       return totalSize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       //return nf.format(totalSize);
       //return totalSize;
  }

  render() {
    //if (localStorage.getItem('token') === null) {
    //var token = localStorage.getItem("accessToken");
    // try {
    //   if (token === null) {
    //     let token_expired = token
    //     let decoded = decode(token_expired, { header: true })
    //     let decode_string = decoded['exp']
    //     var current_time = Date.now() / 1000
    //     if (decode_string < current_time) {
    //       localStorage.clear()
    //     }
    //   }
    // } catch (e) {
    //   localStorage.clear() //what you need to do incase the jwt is not valid
    //   console.log(e) //for your own debugging
    // }
    const beId = this.state.beId;
    const decoded = this.state.decoded;
    const prefix = this.state.prefix;
    const fileSize = this.fileSize();

    //var decoded = jwt_decode(token);
    //var beId = decoded.extension_beId.split(',');
    //var beId = decoded.extension_beId;
    //const prefix = "fh_";
    //var totalRecord = Table.Data.count;
    //console.log(totalRecord);
    return (
      <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.open}
          onClick={this.handleClose.bind}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <Navbar bg="warning" variant="dark" style={{backgroundColor: "white"}}> */}
        <Navbar style={{ backgroundColor: "mediumorchid", height: "50px" }} variant="dark">
          <h2>Proprietary Files</h2>
        </Navbar>
        <h6 className='name m-3'>Username: {decoded.email}</h6>
        <h6 className='name m-3'>Total number of files: {this.state.fileDetails.length}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      Total files size: {fileSize + "  MB"}</h6>
        {/* <h6 className='name m-3'>Total files size: {fileSize + "  MB"} </h6> */}

        <div className='name m-3' style={{ backgroundColor: "white", fontSize: '1em', fontWeight: '500' }}>
          {/* <div className='container ml-3' style={{ border: "solid", backgroundColor: "white", fontSize: 'x-large', fontWeight: '600' }}> */}
            <label className='bucketSelect'>
            File Batch: &nbsp;
            </label>
            {beId ?
            <select maxMenuHeight={220} menuPlacement="auto" className='bucketSelect' value={this.state.selectValue} onChange={this.handleChange} >bucketName
              {beId.map((item) =>
                <option key={item}>{prefix + validator.trim(item)}</option>
              )}
            </select>
              : <h6> Bucket name is not mapped to the user</h6>}
            <div className="App">
            {/* <h5>Provider & Rate Files</h5> */}
              {/* {this.state.fileDetails.length !== 0 ? */}
              {this.state.progress? <div className='root'> </div>:null}
              <FileTable list={this.state.fileDetails} onclick={this.downloadStorageFile.bind(this)} />
                {/* : <Backdrop className={classes.backdrop} open>

                <CircularProgress color="inherit" />
              </Backdrop>
              } */}
           {/*} <Modal show={this.state.progress} dialogClassName='Modal' backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                  Download is in progress
              </Modal.Header>
              <Modal.Body>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </Modal.Body>
            </Modal> */}

            </div>


            {/* hardcoded JSON
          <div>
            <h1>Provider & Rate Files</h1>
            <Table />
          </div> */}


          </div>
          <ToastContainer transition={Zoom} autoClose={2000} draggable={false} position={toast.POSITION.TOP_CENTER} bodyClassName="toastBody"/>

        </>
        )
    //}
  }
}