import React from 'react'
import "../styles/index.css"
import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import LandingPage from './LandingPage';

export default function Login() {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [helloData, setHelloData] = useState(null);
    console.log(useMsal);
    console.log(instance);
    console.log(useState);
    console.log(account);
  
    /// added temp due to useEffect is not calling 

    // instance.acquireTokenSilent({
    //   scopes: ["offline_access 0853e4a9-a10d-4f80-9d1c-b0a8e24a2e25"],
    //   account: account
    // }).then((response) => {
    //   console.log("accessToken");
    //   localStorage.setItem("accessToken", response.accessToken)
    //   console.log(localStorage);
    // })

        /// added temp due to useEffect is not calling 

    function getToken(){
      console.log("Login JSX1");
          if (account && inProgress === "none" && !helloData) {
      console.log("Login JSX2");
      instance.acquireTokenSilent({
          //scopes: ["offline_access 0853e4a9-a10d-4f80-9d1c-b0a8e24a2e25"],
          scopes: ["offline_access 342173e4-0f04-4321-af27-a68ce942dee6"],
          account: account
        }).then((response) => {
          console.log("accessToken");
          localStorage.setItem("accessToken", response.accessToken)
          console.log(localStorage);
        })
      }
    }
 
    getToken();
return (
        <>
            <LandingPage />
        </>
    )
}